<template>
    <div id="breadcrumbs" class="box b-shdw-3">
        <div class="level">
            <div class="level-item" :class="{'active': step === 0, 'prev': step > 0}">
                <div class="line"></div>
                <span class="txt">Quote</span>
                <div class="space">
                    <div class="dot">
                        <b-icon pack="fas" icon="clipboard-list" size="is-medium" class="dot-content"/>
                    </div>
                </div>
            </div>
            <router-link :to="{name:'policyDetails'}" class="level-item" :class="{'active': step === 1, 'prev': step > 1}">
                <div class="line"></div>
                <span class="txt">Results</span>
                <div class="space">
                    <div class="dot">
                        <b-icon pack="fas" icon="check-double" size="is-medium" class="dot-content"/>
                    </div>
                </div>
            </router-link>
            <router-link :to="{name:'yourDetails'}" class="level-item" :class="{'active': step === 2, 'prev': step > 2}">
                <div class="line"></div>
                <span class="txt">Details</span>
                <div class="space">
                    <div class="dot">
                        <b-icon pack="fas" icon="pen-alt" size="is-medium" class="dot-content"/>
                    </div>
                </div>
            </router-link>
            <router-link :to="{name:'purchasePolicy'}" class="level-item" :class="{'active': step === 3, 'prev': step > 3}">
                <div class="line"></div>
                <span class="txt">Purchase</span>
                <div class="space">
                    <div class="dot">
                        <b-icon pack="fas" icon="check-circle" size="is-medium" class="dot-content"/>
                    </div>
                </div>
            </router-link>
            <div class="level-item" :class="{'active': step === 4, 'prev': step > 4}">
                <span class="txt">Confirmation</span>
                <div class="space">
                    <div class="dot">
                        <b-icon pack="fas" icon="plane" size="is-medium" class="dot-content"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "QuoteBreadcrumb",
        props: {
            step: {
                default: 0,
                type: Number,
                required: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    #breadcrumbs {
        padding: 10px;
    }
    @media screen and (max-width: 1400px) {
        .level-item {
            display: none;
        }
        .active {
            display: flex;
            padding-bottom: 0;
        }
        .line {
            margin-left: 0 !important;
        }
    }
    @media screen and (max-width: 767px) {
        #breadcrumbs {
            padding-bottom: 1px;
        }
    }

    // Default
    .dot {
        border: 4px solid #a6a6a6;
        border-radius: 50%;
        margin-left: 0px;
        margin-right: 10px;
        height: 30px;
        width: 30px;
        color: white !important;
        background-color: #a6a6a6;
        z-index: 1;
    }
    .dot-content {
        margin-left: -5px;
        font-size: 12px;
        position: relative;
        bottom: 5px;
        padding: 0 5px 0 5px;
    }
    .level-item {
        font-weight: bold;
        font-size: 20px;
        color: #a6a6a6;
    }
    .txt {
        z-index: 1;
        background-color: white;
        padding: 0 5px 0 10px;
        margin-left: 10px;
    }
    .space {
        background-color: white;
        width: 40px;
        height: 30px;
        z-index: 0;
    }
    .line {
        border: 2px solid #a6a6a6;
        width: 250px;
        position: absolute;
        margin-left: 150px;
    }

    // Previous crumbs
    .prev {
        /*color: $c2g_orange !important;*/
        .dot {
            color: white !important;
            border-color: $c2g_orange !important;
            background-color: $c2g_orange !important;
        }
        .line {
            border-color: $c2g_orange;
        }
    }

    // Active crumb
    .active {
        color: #4D4D4D !important;
        .dot {
            border-color: $c2g_orange !important;
            background-color: white !important;
            color: $c2g_orange !important;
            height: 40px;
            width: 40px;
            bottom: 5px !important;
            position: relative;
        }
        .dot-content {
            margin-left: 0px;
            margin-top: 3px;
            bottom: 3px;
            font-size: 15px;
            position: relative;
        }
        .space {
            width: 50px;
        }
    }
</style>